export const getParagraphIds = (intlMessages: any, dataId: string, headlineKey: string, copytextKey: string): any[] => {
    const p: any[] = [];
    const entries = Object.keys(intlMessages).filter((key) => key.startsWith(dataId));
    entries.forEach((entry) => {
        const splitString1 = entry.split(dataId + '.');
        const splitString2 = splitString1[1].split('.');
        const index = parseInt(splitString2[0], 10);

        if (!p[index]) {
            p[index] = { [headlineKey]: '', [copytextKey]: [] };
        }
        if (splitString2[1] === headlineKey) {
            p[index][headlineKey] = entry;
        }
        if (splitString2[1] === copytextKey) {
            if (!p[index][copytextKey]) {
                p[index][copytextKey] = [];
            }
            p[index][copytextKey].push(entry);
        }
    });
    return p;
};
