import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import LogoMirror from '../../_assets/images/logos/logo-mirror-blue.svg';
import { Color } from '../../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../../components/common/page-layout/page-layout';
import FaqEntries from '../../components/templates/faq-entries/faq-entries';
import HeadlineCopytext from '../../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../../components/templates/responsive-image/responsive-image';
import SectionIntended from '../../components/templates/section-intended/section-intended';

import FaqStyles from './_scss/faq.module.scss';

const FaqMirrorPage = () => {
    const intl = useIntl();

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.faqMirror.title' })}
            pageBackground={PageLayoutBackground.Black}
            topPadding={false}
        >
            <div className={FaqStyles.faqHead}>
                <ResponsiveImage
                    images={[
                        {
                            path: '_assets/pages/service/faq-mirror/header.jpg',
                            media: '',
                        },
                    ]}
                    altText=""
                />

                <div className={FaqStyles.faqHeadContent}>
                    <HeadlineCopytext
                        alignment="center"
                        color={Color.White}
                        headline={[
                            { text: intl.formatMessage({ id: 'pages.faqMirror.headline.0' }), style: 'h1' },
                            { text: intl.formatMessage({ id: 'pages.faqMirror.headline.1' }), style: 'h2' },
                        ]}
                    />
                </div>
            </div>

            <SectionIntended noPadding={true}>
                <div className={FaqStyles.faqSubhead}>
                    <img src={LogoMirror} height="26" alt="@Mirror" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqMirror.firstSteps.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqMirror.firstSteps.questions" activeEntry={0} />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqMirror.training.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqMirror.training.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqMirror.handling.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqMirror.handling.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqMirror.troubleshooting.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqMirror.troubleshooting.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqMirror.subscription.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqMirror.subscription.questions" />
                </div>
            </SectionIntended>
        </PageLayout>
    );
};

export default FaqMirrorPage;
