import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { getParagraphIds } from '../../../modules/paragraphs';
import { richText } from '../../../modules/rich-text';

import FaqEntriesStyles from './faq-entries.module.scss';

const FaqEntries = ({ messageNodeId, activeEntry }: { messageNodeId: string; activeEntry?: number }) => {
    const intl = useIntl();
    const questions = getParagraphIds(intl.messages, messageNodeId, 'question', 'answer');
    const [activeIndex, setActiveIndex] = useState(typeof activeEntry === 'number' ? activeEntry : -1);

    const toggle = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <ul>
            {questions.map((entry, entryIndex) => {
                return (
                    <li
                        key={entryIndex}
                        className={[
                            FaqEntriesStyles.faqEntries,
                            messageNodeId.indexOf('faqMirror') > -1 ? FaqEntriesStyles.mirror : '',
                            activeIndex === entryIndex ? FaqEntriesStyles.active : '',
                        ].join(' ')}
                    >
                        <button className="reset-button" onClick={() => toggle(entryIndex)}>
                            <p className="bold">
                                <FormattedMessage id={entry.question} />
                            </p>
                        </button>
                        <div>
                            {questions[entryIndex].answer.map(
                                (answer: string, answerIndex: string | number | undefined) => {
                                    return (
                                        <p key={answerIndex} className="color-white">
                                            {richText(answer)}
                                        </p>
                                    );
                                },
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default FaqEntries;
